//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import constant from '~/const';

export default {
  props: {
    value: { type: Boolean, default: false },
    data: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      input: {},
      constant,
      savedData: {},
      statuses: [
        { id: 6, name: 'Cancelled' },
        { id: 5, name: 'Completed' },
      ],
    };
  },

  computed: {
    ...mapState('tradersRequisites', ['userData']),

    model: {
      get() {
        const { value } = this;
        if (value) {
          this.loadSavedData();
        }
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    isApplyPossible() {
      return this.input.status && this.input.comment;
    },
  },

  methods: {
    apply() {
      this.input.tradeId = this.$props.data.id;
      const { input } = this;
      const status = {};

      if (this.input.status === 5) {
        status.setCompleted = true;
      } else if (this.input.status === 6) {
        status.setCancelled = true;
      }

      this.saveData();

      this.$emit('apply', { input, status });
    },

    handleOutsideClick() {
      this.saveData();
      this.resetModal();
    },

    resetModal() {
      this.input = {};
    },

    getStatus(current) {
      let res;
      switch (current) {
        case 5:
          res = this.statuses.filter((e) => e.id !== current);
          break;
        case 6:
          res = this.statuses.filter((e) => e.id !== current);
          break;
        default:
          res = this.statuses;
          break;
      }
      return res;
    },

    saveData() {
      const { id } = this.$props.data;
      this.savedData[id] = { ...this.input };
    },

    loadSavedData() {
      const { id } = this.$props.data;
      if (this.savedData[id]) {
        this.input = { ...this.savedData[id] };
      } else {
        this.resetModal();
      }
    },
  },
};
