//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import constant from '~/const';

export default {
  props: {
    type: { type: [String, Number], default: '' },
    command: { type: Object, default: () => ({}) },
    disabled: { type: Boolean, default: false },
  },

  computed: {
    isP2Pay() {
      return process.env.VUE_APP_EXCLUDE_FEATURE?.split(' ').includes('transactions-old');
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('reports', {
      createReportAction: 'createReport',
    }),

    async createReport() {
      const { type, command } = this;
      this.setGeneralProgress(true);
      try {
        const defaultData = {
          limit: this.isP2Pay ? String(constant.reports.LIMIT) : constant.reports.LIMIT,
          offset: this.isP2Pay ? '0' : 0,
        };

        const payload = this.isP2Pay
          ? { type, request: { ...command } }
          : { type, data: { ...defaultData, ...command } };

        await this.createReportAction(payload);
        this.confirmAction({
          title: this.$t('common.Request created'),
          text: this.$t('common.Request for report has been created'),
          hasCancelButton: false,
        });
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.setGeneralProgress(false);
    },
  },
};
