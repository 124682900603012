//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import User from '~/components/User.vue';

import constant from '~/const';

export default {
  components: {
    User,
  },

  props: {
    data: { type: Object, required: true },
  },

  data() {
    return {
      constant,
    };
  },

  computed: {
    getStatusHistory() {
      return this.$props.data?.statusHistory.slice().reverse();
    },
  },

  methods: {
    openOfferById(offerId) {
      this.$emit('openOfferByIdAction', offerId);
    },

    getConvertedProfit(profit, rate) {
      const res = profit * rate;
      return Number.isInteger(res) ? res : parseFloat(res.toFixed(5));
    },

    transformUid(str) {
      if (str.length <= 16) return str;
      return `${str.slice(0, 8)}....${str.slice(-8)}`;
    },

    paymentMethodStatusClass(status) {
      return status === constant.traders.PAYMENT_METHOD_STATUS.ENABLED
        ? 'success--text'
        : 'error--text';
    },
  },
};
