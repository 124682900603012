export default [
  {
    width: 18,
    header: {
      type: 'text',
      caption: 'Id',
    },
    cell: {
      type: 'text',
      value: (e) => e.id,
      tooltip: true,
    },
  },
  {
    width: 25,
    header: {
      type: 'text',
      caption: 'Seller amount',
    },
    cell: {
      type: 'text',
      value: (e) => `${e.sellerAmount} ${e.sellerCurrency}`,
    },
  },
  {
    width: 25,
    header: {
      type: 'text',
      caption: 'Buyer amount',
    },
    cell: {
      type: 'text',
      value: (e) => `${e.buyerAmount} ${e.buyerCurrency}`,
    },
  },
  {
    width: 28,
    header: {
      type: 'text',
      caption: 'Seller name',
    },
    cell: {
      type: 'slot',
      name: 'seller',
    },
  },
  {
    width: 28,
    header: {
      type: 'text',
      caption: 'Buyer name',
    },
    cell: {
      type: 'slot',
      name: 'buyer',
    },
  },
  {
    width: 18,
    header: {
      type: 'text',
      caption: 'Status',
    },
    cell: {
      type: 'slot',
      name: 'status',
    },
  },
  {
    width: 16,
    header: {
      type: 'text',
      caption: 'Create date',
    },
    cell: {
      type: 'slot',
      name: 'createDate',
    },
  },
  {
    width: 16,
    header: {
      type: 'text',
      caption: 'Expiration date',
    },
    cell: {
      type: 'slot',
      name: 'expirationDate',
    },
  },
  {
    width: 16,
    header: {
      type: 'text',
      caption: 'Property sent date',
    },
    cell: {
      type: 'slot',
      name: 'PropertySentDate',
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Arbitrator username',
    },
    cell: {
      type: 'text',
      value: (e) => e.arbitratorUsername,
      tooltip: true,
    },
  },
  {
    width: 40,
    header: {
      type: 'text',
      caption: 'Controls',
    },
    cell: {
      type: 'slot',
      name: 'controls',
    },
  },
];
